// Importa BrowserRouter e gli altri componenti necessari dal pacchetto "react-router-dom"
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import App from "./App";
import PornoIndex from "./components/PornoIndex";
import DonutIndex from "./components/donut-index";
import DonutMint from "./components/donut-mint";

const Routing = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={App} />
      <Route path="/pornoglass" component={PornoIndex} />
      <Route path="/donutday" component={DonutIndex} />
      <Route path="/donutdayMINT" component={DonutMint} />{" "}
    </Switch>
  </Router>
);

export default Routing;
