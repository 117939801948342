import React, { useEffect } from "react";
import DonutNav from "./donut-nav"; // Assicurati di importare la tua navbar corretta

const DonutPass = ({ hideNavbar }) => {
  useEffect(() => {
    // Controlla se hideNavbar è una funzione prima di chiamarla
    if (typeof hideNavbar === "function") {
      hideNavbar(); // Chiama la funzione hideNavbar per nascondere la navbar
    }
  }, [hideNavbar]);

  return (
    <div id="/Pass" className="passdiv">
      <div className="contpass">
        <img
          src="img/donut/donpass.png"
          alt="Donut PASS text"
          className="pass"
        />
        <div className="column">
          <img
            src="img/donut/dontext.png"
            alt="Donut PASS text"
            className="logopass"
          />
          <div className="icodivpass">
            <div>
              <a
                href="https://polygonscan.com/address/0x5ff9cc7efacee3c614a0d7b8237f4d353105f61b"
                target="_blank"
              >
                <img
                  src="img/donut/esico.png"
                  alt="Polygonscan"
                  className="icodis icotrasfhov "
                />
              </a>
            </div>
            <div>
              <a
                href="https://opensea.io/collection/seasone-one-pass"
                target="_blank"
              >
                <img
                  src="img/donut/osico.png"
                  alt="OpenSea"
                  className="icodis icotrasfhov "
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonutPass;
