import React, { useState, useEffect } from "react";
// Aggiungi questa riga
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";

import { Helmet } from "react-helmet";
import { Navigation } from "./components/navigation";
import Header from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
//import Giveaway from "./components/giveaway";
//import Gallery from "./components/gallery";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import PornoIndex from "./components/PornoIndex";
import DonutIndex from "./components/donut-index"; // Importa il componente DonutIndex

import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
//import DonutWhitelist from "./components/donut-whitelist";
//import DonutWL from "./components/donutWLpage";
//import DonutWLpage from "./components/donutWLpage";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const location = useLocation(); // Importa e utilizza useLocation

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        document.title = "Come back to us, Miss you.";
      } else {
        // Cambia il titolo in base alla pagina corrente
        if (location.pathname === "/") {
          document.title = "Idiot's Club";
        } else if (location.pathname === "/pornoglass") {
          document.title = "Porno Glass";
        } else if (location.pathname === "/donutday") {
          document.title = "Donut Day";
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [location]); // Aggiungi solo "location" come dipendenza

  return (
    <Router>
      <div>
        <Helmet>
          <title>Idiot's Club</title>
        </Helmet>
        <Navigation />
        <Switch>
          <Route exact path="/">
            <Header data={landingPageData.Header} />
            <Features data={landingPageData.Features} />
            <About data={landingPageData.About} />
            <Services data={landingPageData.Services} />
            {/*} <Giveaway data={landingPageData.Giveaway} />
           <Gallery data={landingPageData.Gallery} /> */}
            <Team data={landingPageData.Team} />
            <Contact data={landingPageData.Contact} />
          </Route>
          <Route path="/pornoglass" component={PornoIndex} />
          <Route path="/donutday" component={DonutIndex} />
          {/*} <Route path="/donutWL" component={DonutWLpage} /> */}
        </Switch>
      </div>
    </Router>
  );
};

export default App;
