import React, { useEffect } from "react";
import NavbarPorn from "./NavbarPorn";

const Pornoheader = ({ hideNavbar }) => {
  useEffect(() => {
    // Controlla se hideNavbar è una funzione prima di chiamarla
    if (typeof hideNavbar === "function") {
      hideNavbar(); // Chiama la funzione hideNavbar per nascondere la navbar
    }
  }, [hideNavbar]);

  return (
    <div id="Home">
      <NavbarPorn />
      <div className="pornobody">
        {/* Prima immagine con spazio di classe */}
        <div className="bgbody">
          <img src="img/porn/BGhead.png" alt="BGBody" />
        </div>
        <div className="pornLogo">
          <img src="img/porn/logo-porn.png" alt="Logo POR.no Glass" />
        </div>
      </div>
      <div className="linea-fondo"></div>
    </div>
  );
};

export default Pornoheader;
