import React, { useEffect } from "react";
// Aggiungi questa riga
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./donut-style.css"; // Usa il file CSS corretto per Donut Day
import DonutNav from "./donut-nav"; // Importa il componente DonutNav

import DonutHeader from "./donut-header"; // Importa il componente DonutHeader
import DonutPass from "./donut-pass";
import DonutAbout from "./donut-about";
import DonutMaps from "./donut-maps";
import DonutCollabVertScroll from "./donut-collab";
import DonutContact from "./donut-contact";
import DonutRando from "./donut-randoinfo";

// Importa gli altri componenti quando sei pronto ad usarli

// import DonutAbout from "./donut-about";
// import DonutMaps from "./donut-maps";
// import DonutContact from "./donut-contact";

const DonutIndex = ({ hideNavbar }) => {
  useEffect(() => {
    // Controlla se hideNavbar è una funzione prima di chiamarla
    if (typeof hideNavbar === "function") {
      hideNavbar(); // Chiama la funzione hideNavbar per nascondere la navbar
    }
  }, [hideNavbar]);

  useEffect(() => {
    let title = document.title;
    document.title = "Donut Day";
    return () => (document.title = title);
  });

  return (
    <Router>
      <div className="totalbggrad">
        <DonutNav />
        <div id="Home">
          <DonutHeader hideNavbar={hideNavbar} />
        </div>
        <div id="Pass">
          <DonutPass />
        </div>
        <div id="About">
          <DonutAbout />
        </div>{" "}
        <div id="About">
          <DonutRando />
        </div>
        <div id="Maps">
          <DonutMaps />
        </div>
        <div id="Collab">
          <DonutCollabVertScroll />
        </div>
        <div id="About">
          <DonutContact />
        </div>
        {/* Aggiungi questa riga per la nuova pagina */}
        {/* Aggiungi altri componenti per le diverse sezioni
        
        <div id="About">
          <DonutAbout />
        </div>

        <div id="Maps">
          <DonutMaps />
        </div>

        <div id="Contact">
          <DonutContact />
        </div>

        */}
      </div>
    </Router>
  );
};

export default DonutIndex;
