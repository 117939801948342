import React from "react";
import { Link } from "react-router-dom";

export const NavbarPorn = () => {
  return (
    <nav id="menu-porn" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>

          <a className="navbar-brand page-scroll" href="/donutday">
            DONUT DAY
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#/DonutContact" className="page-scroll">
                Contact
              </a>
            </li>
          </ul>
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#/Collab" className="page-scroll">
                Your Style
              </a>
            </li>
          </ul>
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#/Road-Maps" className="page-scroll">
                Road-Maps
              </a>
            </li>
          </ul>
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#/WhatIs" className="page-scroll">
                What is?
              </a>
            </li>
          </ul>
          {/*<ul className="nav navbar-nav navbar-right">
            <li>
              <a
                href="https://donutmint.idiots-club.com/"
                className="page-scroll"
              >
                MINT
              </a>
            </li>
  </ul>*/}
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#/Pass" className="page-scroll">
                Pass
              </a>
            </li>
          </ul>
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#/Home" className="page-scroll">
                Home
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavbarPorn;
